import { ChevronDownIcon, SearchIcon } from "@heroicons/react/outline";
import { useClassNames } from '@metaforcelabs/metaforce-core';
import React, { useState, useEffect } from "react";
import WorkflowEventAppIcon from "../workflowAppIcon";

export const StepValueOptionsFilter = ({searchTerm, handleSearch}) => {
    return (
        <div>
            <div className="px-2 pt-2 flex">
                <div className="mt-3 w-full">
                    <label htmlFor="desktop-search-candidate" className="sr-only">
                        Search
                    </label>
                    <div className="flex rounded-md shadow-sm">
                        <div className="relative flex-grow focus-within:z-10">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <SearchIcon
                                    className="h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </div>
                            <input
                                type="text"
                                name="desktop-search-candidate"
                                id="desktop-search-candidate"
                                className="hidden focus:ring-brand-pink focus:border-brand-pink w-full rounded-md pl-10 sm:block sm:text-sm border-gray-300"
                                placeholder="Search"
                                onChange={e => handleSearch(e.target)}
                                value={searchTerm}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const StepValueOptions = ({ stepValues, step, onAdd, isCollapsedDefault, searchTerm, countOptions = null }) => {
    const { classNames } = useClassNames();

    const [showAll, setShowAll] = useState(false)
    const [isCollapsed, setIsCollapsed] = useState(!isCollapsedDefault)
    const [filteredValues, setFilteredValues] = useState(stepValues)

    useEffect(() => {
        if (!searchTerm) {
            setFilteredValues(stepValues);
            return;
        }

        setFilteredValues(stepValues.filter(o => o.name.includes(searchTerm)));
    }, [searchTerm]);

    const countCls = countOptions?.color && countOptions?.show ? countOptions.color : "";

    return (
        <div>
            <div className="px-2 pt-2 flex justify-between items-center cursor-pointer" onClick={e => setIsCollapsed(prev => (!prev))}>
                <h3 className="font-semibold">
                    <div className="flex items-center space-x-2 cursor-pointer">
                        <WorkflowEventAppIcon iconKey={step.appEvent?.appIcon} className="flex-none h-8 w-8 p-1 rounded shadow" />
                        <h3 className="font-semibold text-lg"> <span>{step.stepIdx + 1}. {step.name}</span></h3>
                        {countOptions?.show && (<span className={countCls}>({filteredValues.length})</span>)}
                    </div></h3>
                <div >
                    <ChevronDownIcon className="text-gray-600 h-5 w-5" />
                </div>
            </div>
            {
                !isCollapsed && (
                    <div className="my-4 ml-2 space-y-2">
                        {
                            (showAll ? filteredValues : filteredValues?.slice(0, 5))?.map(o => {
                                return (
                                    <div key={o.id} className="flex items-center space-x-2 cursor-pointer rounded-lg border-gray-200 border p-1 hover:shadow"
                                        onClick={e => {
                                            onAdd(o);
                                        }}
                                    >
                                        <WorkflowEventAppIcon iconKey={step.appEvent?.appIcon} className="flex-none h-6 w-6 shadow rounded p-1" />
                                        <div className="font-semibold text-md"> <span>{step.stepIdx + 1}. {o.name} {o.isArray ? <span className="font-bold text-gray-400 text-sm">{'[ ]'}</span>:''}</span></div>
                                    </div>
                                )
                            })
                        }
                        {
                            !showAll && filteredValues.length > 5 && (
                                <div className="p-1">
                                    <div className="font-semibold hover:underline cursor-pointer"
                                        onClick={e => setShowAll(true)}>
                                        Show all...
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )

            }
        </div>
    )
}