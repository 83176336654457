import { workflowTableFieldType, workflowTableRowStatus, workflowTableFiltersEligible, workflowTableFilterNames } from "../utils/constants";

export const useTableFilterHelper = () => {
    const booleanOptions = [
        {
            label: "True",
            name: "True",
            value: "true"
        },
        {
            label: "False",
            name: "False",
            value: "false"
        }
    ]

    const statusOptions = Object.keys(workflowTableRowStatus).map(k => {
        const capitalized = k.slice(0, 1).toUpperCase() + k.slice(1);
        return {
            label: capitalized,
            name: capitalized,
            value: workflowTableRowStatus[k].toString()
        }
    })

    const getFilterableProperties = (tableData) => {
        return [
            {
                id: "Status",
                name: "Status",
                type: workflowTableFieldType.status,
            },
            {
                id: "Retries",
                name: "Retries",
                type: workflowTableFieldType.integer,
            },
            ...tableData.fields.filter(f => f.searchable)
        ];
    }

    const filterOperationAdd = (filters) => [
        ...filters,
    {
            property: null,
            type: null,
            value: '',
            fieldName: null,
            fieldType: null,
            valid: false
    }
    ]

    const filterOperationRemove = (filters, idx) => [
        ...filters.slice(0, idx),
        ...filters.slice(idx + 1)
    ]

    const filterOperationSetValue = (filters, idx, value) => {
        const newFilters = [
            ...filters.slice(0, idx),
            {
                ...filters[idx],
                ...value
            },
            ...filters.slice(idx + 1)
        ];
        newFilters[idx].valid = newFilters[idx].property != null && newFilters[idx].type != null && newFilters[idx].value != '';
        return newFilters; 
    }

    const getFilterPropetyOptions = (filterableProperties, filters, idx) => filterableProperties.filter(f => !filters.find((x, i) => (i != idx) && (x.property == f.id))).map(f => ({
        label: f.name,
        name: f.name,
        value: f.id
    }))

    const getFilterPropetyOnchangeValue = (filterableProperties, value) => {
        const selectedProperty = filterableProperties.find(f => f.id == value);
        return {
            property: value,
            fieldName: selectedProperty.name,
            fieldType: selectedProperty.type,
            type: workflowTableFiltersEligible[selectedProperty.type].length == 1 ? workflowTableFiltersEligible[selectedProperty.type][0] : null
        };
    }

    const getFilterPropetyValue = (filter) => filter.property != undefined ? {
        label: filter.fieldName,
        name: filter.fieldName,
        value: filter.property
    } : null

    const getFilterTypeOptions = (filter) => workflowTableFiltersEligible[filter.fieldType].map(f => ({
        label: workflowTableFilterNames[f],
        name: workflowTableFilterNames[f],
        value: f
    }))

    const getFilterTypeValue = (filter) => filter.type != undefined ? {
        label: workflowTableFilterNames[filter.type],
        name: workflowTableFilterNames[filter.type],
        value: filter.type
    } : null

    const getFilterValueOptions = (filter) => filter.fieldType == workflowTableFieldType.status ? statusOptions : booleanOptions

    const getFilterValueValue = (filter) => (filter.fieldType == workflowTableFieldType.status ? statusOptions : booleanOptions).find(o => o.value == filter.value)

    const getFilterValueType = (filter) => ["text", "number", "number", "", "datetime-local"][filter.fieldType]

    return {
        booleanOptions: booleanOptions,
        statusOptions: statusOptions,
        getFilterableProperties: getFilterableProperties,
        filterOperationAdd: filterOperationAdd,
        filterOperationRemove: filterOperationRemove,
        filterOperationSetValue: filterOperationSetValue,
        getFilterPropetyOptions: getFilterPropetyOptions,
        getFilterPropetyOnchangeValue: getFilterPropetyOnchangeValue,
        getFilterPropetyValue: getFilterPropetyValue,
        getFilterTypeOptions: getFilterTypeOptions,
        getFilterTypeValue: getFilterTypeValue,
        getFilterValueOptions: getFilterValueOptions,
        getFilterValueValue: getFilterValueValue,
        getFilterValueType: getFilterValueType
    }
}